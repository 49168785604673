html {
  background-color: "#041C32";
}

body {
  background-color: "#041C32";

  margin: 0;
  font-family: "Rubik", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
}

code {
  font-family: "Rubik", sans-serif;
}
